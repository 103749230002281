let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-wecomplai-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-wecomplai-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://qv7ofgkdlnfs5b62ishakbozhq.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://s50d2o3bnl.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://breglcjba1.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.wecomplai.forwoodsafety.com",
        WEBSOCKET: "wss://ko46w5f5id.execute-api.us-west-2.amazonaws.com/staging"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_8ksFoTqm2",
        IDENTITY_POOL_ID: "us-west-2:31f43f0f-665a-4eb8-b277-16da7fec61c8",
        USERPOOL_HOSTED_DOMAIN: "forwood-wecomplai-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.wecomplai.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.wecomplai.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::861477874339:role/staging-CA"
    },
    samlProvider: {
      NAME: 'ForwoodAzure',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.6",
        HOSTNAME: "id.staging.wecomplai.forwoodsafety.com",
        ENV_NAME: "staging",
        COOKIE_DOMAIN: ".staging.wecomplai.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "25cfb156-0f99-4fb1-b5ba-d0e3539564e3",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.wecomplai.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
